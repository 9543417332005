<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder">Site Usage <span style="font-size: 0.5em" class="ml-2 text-muted"> (past 24 hours)</span></h3>
			<div class="col-3 d-flex align-items-center">
				<div style="width: 100%">
					<b-form-group label="Sort:" label-align-sm="left" label-for="sortselect" label-cols-lg="2">
						<b-form-select
							v-model="selectedSort"
							id="sortselect"
							:options="[
								{ value: 'rxdesc', text: 'Downstream Desc' },
								{ value: 'txdesc', text: 'Upstream Desc' },
								{ value: 'rxasc', text: 'Downstream Asc' },
								{ value: 'txasc', text: 'Upstream Asc' },
							]"
							@change="sortGraph(selectedSort)"
						></b-form-select>
					</b-form-group>
				</div>
			</div>
		</div>

		<div v-if="error" class="text-center my-10">
			<h4>{{ errorMsg }}</h4>
		</div>
		<div v-else class="barChartContainer mb-10 ml-5">
			<div v-for="(d, i) of data" v-bind:key="i">
				<div class="row border-top pt-2 mr-2">
					<div class="col-md-3" style="line-height: 40px; height: 40px; overflow: hidden">
						<router-link :to="{ name: 'site', params: { id: d.SiteId } }">{{ d.name }} </router-link>
					</div>
					<div class="col-md-9">
						<div class="row">
							<div class="col-md-12 pb-2">
								<b-progress :max="maxrx" v-b-tooltip="'Downstream: ' + kmg(d.rx) + 'B'"
									><b-progress-bar :value="d.rx"> {{ kmg(d.rx) }}B</b-progress-bar>
								</b-progress>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<b-progress :max="maxtx" variant="success" v-b-tooltip="'Upstream: ' + kmg(d.tx) + 'B'"
									><b-progress-bar :value="d.tx"> {{ kmg(d.tx) }}B</b-progress-bar>
								</b-progress>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { KMG } from '@/helpers';
import swMixin from '@/core/services/mixins/serviceworker.mixin';

export default {
	name: 'DashSiteUsage',
	mixins: [swMixin],
	data() {
		return {
			loaded: false,
			error: false,
			errorMsg: null,
			selectedSort: null,
			data: [],
			maxrx: 0,
			maxtx: 0,
		};
	},
	methods: {
		kmg(v) {
			return KMG(v);
		},
		processData(d) {
			if (!d || d.length == 0) {
				this.dontShowGraph = true;
				return null;
			}
			this.data = [];
			for (var i = 0; i < d.length; i++) {
				if (d[i].rx > this.maxrx) {
					this.maxrx = d[i].rx;
				}

				if (d[i].tx > this.maxtx) {
					this.maxtx = d[i].tx;
				}

				this.data.push({ name: d[i].Name, SiteId: d[i].SiteId, rx: d[i].rx, tx: d[i].tx });
			}

			this.sortGraph('rxdesc');
		},
		sortGraph(dir) {
			if (dir == 'rxdesc') {
				this.data.sort(function(a, b) {
					return b.rx - a.rx;
				});
			}
			if (dir == 'rxasc') {
				this.data.sort(function(a, b) {
					return a.rx - b.rx;
				});
			}
			if (dir == 'txdesc') {
				this.data.sort(function(a, b) {
					return b.tx - a.tx;
				});
			}
			if (dir == 'txasc') {
				this.data.sort(function(a, b) {
					return a.tx - b.tx;
				});
			}
		},
	},
	created() {
		this.loaded = false;
		this.$http.get('dashboard/siteusage').then(resp => {
			if (resp.data.data?.error === 404) {
				this.error = true;
				this.errorMsg = resp.data.data.message;
			} else {
				this.processData(resp.data.data);
			}
			this.loaded = true;
		});
		this.setSWListener('dashboard/siteusage', resp => {
			if (resp.data.data?.error === 404) {
				this.error = true;
				this.errorMsg = resp.data.data.message;
			} else {
				this.processData(resp.data.data);
			}
		});
	},
};
</script>

<style>
.barChartContainer {
	min-height: 400px;
	max-height: 400px;
	overflow-y: auto;
	overflow-x: hidden;
}
</style>
